import { memo, useCallback } from 'react';
import { MapMarkerFeature, Marker, MarkerEvent } from '/entities/Marker';

interface MarkersListProps {
  markersFeature: MapMarkerFeature[];
  selectedMarker: MapMarkerFeature | null;
  onSelect?: (marker: MapMarkerFeature, e: MarkerEvent) => void;
  onClick?: (marker: MapMarkerFeature, e: MarkerEvent) => void;
}

export const MarkersList = memo((props: MarkersListProps) => {
  const { markersFeature, selectedMarker, onSelect, onClick } = props;

  const onMarkerClick = useCallback(
    (marker: MapMarkerFeature) => (e: MarkerEvent) => {
      onClick?.(marker, e);
    },
    [],
  );

  const onMarkerSelect = useCallback(
    (marker: MapMarkerFeature) => (e: MarkerEvent) => {
      onSelect?.(marker, e);
    },
    [],
  );

  return markersFeature.map((f) => {
    return (
      <Marker
        key={f.id}
        latitude={f.properties.lat}
        longitude={f.properties.lon}
        selected={f.id === selectedMarker?.id}
        onClick={onMarkerClick(f)}
        onSelect={onMarkerSelect(f)}
        visited={f.properties.visited}
      />
    );
  });
});
