import { StyleSpecification } from 'maplibre-gl';
import { layers_vizGrayscale } from '../style/vizGrayscale';

export const mapStyle: StyleSpecification = {
  version: 8,
  sources: {
    protomaps: {
      type: 'vector',
      url: 'pmtiles://' + import.meta.env.VITE_PMTILES,
    },
  },
  // @ts-ignore // TODO
  layers: [...layers_vizGrayscale], //  ...clusterLayers
  sprite: import.meta.env.VITE_MAP_SPRITE,
  glyphs: import.meta.env.VITE_MAP_GLYPHS,
};
