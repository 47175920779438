import { memo } from 'react';
import { GeolocateControl, FullscreenControl, NavigationControl } from 'react-map-gl/maplibre';
import { isMobile } from 'react-device-detect';

export const MapControl = memo(() => {
  const style: React.CSSProperties = {
    marginTop: '80px',
  };

  return (
    <>
      <GeolocateControl position='top-right' style={isMobile ? style : {}} />
      <FullscreenControl position='top-right' containerId='map-content' />
      <NavigationControl position='top-right' />
    </>
  );
});
