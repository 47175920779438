import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
  clusterLayerId,
} from '/entities/MapMarkersLayer/';
import {
  GeoJSONSource,
  Layer,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  Source,
  useMap,
} from 'react-map-gl/maplibre';
import { MapMarkers } from '/features/MapMarkers';
import { MapSourceDataEvent } from 'react-map-gl';
import { useMapStore, getMapReady } from '/widgets/MapGL/';
import { CLUSTER_SOURCE_ID } from '../model/const/sourceId';
import { useGetGeojsonService } from '../model/services/useGetGeojsonService';
import {
  getClusterSourceCurrentGeojson,
  getClusterSourceIsLoaded,
  getFnClusterSourceIsLoaded,
} from '../model/selectors/clusterSourceSelectors';
import { useClusterSourceStore } from '../model/store/clusterSourceStore';
import { useMapLoadResources } from '../model/services/useMapLoadResources';
import { Map as MaplibreMap } from 'maplibre-gl';
import { MapRef as GLMapRef } from 'react-map-gl/dist/esm/mapbox/create-ref';
import { initialGeojson } from '../model/const/initialGeojson';
import { useDebounce } from '/shared/hooks/useDebounce';

interface MapSourceWithMapProps {
  map: GLMapRef<MaplibreMap>;
}

const MapSourceWithMap = memo((props: MapSourceWithMapProps) => {
  const { map } = props;

  const [mapFeatures, setMapFeatures] = useState<MapGeoJSONFeature[]>([]); // map all features

  const onceSub = useRef(true);

  const geojson = useClusterSourceStore(getClusterSourceCurrentGeojson);

  // useGetGeojsonService();
  useMapLoadResources(map);

  const updateMapFeatures = useCallback(() => {
    const features = map.querySourceFeatures(CLUSTER_SOURCE_ID);
    if (features) {
      // console.log('updateMapFeatures', features);
      setMapFeatures(features);
    }
  }, []);

  const debouncedUpdateMapFeatures = useDebounce(updateMapFeatures, 300);

  const onSourcedataHandler = useCallback((e: MapSourceDataEvent) => {
    if (CLUSTER_SOURCE_ID === e.sourceId && e.isSourceLoaded === true) {
      // console.log('debouncedUpdateMapFeatures');
      debouncedUpdateMapFeatures();
    }
  }, []);

  // подписки
  useEffect(() => {
    if (onceSub.current) {
      // console.log('test useEffect sub ');
      map.on('sourcedata', onSourcedataHandler);
      map.on('moveend', debouncedUpdateMapFeatures);
      onceSub.current = false;
    }

    // return () => {
    //   if (!testOnceSub.current) {
    //     map.off('sourcedata', onSourcedataHandler);
    //   }
    // };
  }, []);

  const onClickCluster = useCallback((event: MapLayerMouseEvent) => {
    if (!event || !event.features?.[0]) return;

    const feature = event.features[0];
    const clusterId = feature.properties.cluster_id;

    if (feature.properties.cluster) {
      // cluster
      const source = map.getSource(CLUSTER_SOURCE_ID) as GeoJSONSource;

      source?.getClusterExpansionZoom(clusterId).then((zoom) => {
        if (zoom) {
          map.easeTo({
            // @ts-ignore
            center: feature.geometry.coordinates, // TODO
            zoom,
            duration: 400,
          });
        }
      });
    }
  }, []);

  // подписка под клик на кластер
  useEffect(() => {
    map.off('click', clusterLayerId, onClickCluster).on('click', clusterLayerId, onClickCluster);
    return () => {
      map.off('click', clusterLayerId, onClickCluster);
    };
  }, [onClickCluster]);

  return (
    <Source
      id={CLUSTER_SOURCE_ID}
      type='geojson'
      data={geojson || initialGeojson}
      // data={import.meta.env.VITE_GEOJSON}
      cluster
      clusterMaxZoom={14}
      clusterRadius={50}
    >
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />

      <MapMarkers mapFeatures={mapFeatures} />
    </Source>
  );
});

export const MapSource = memo(() => {
  const mapReady = useMapStore(getMapReady);
  const map = useMap();

  return !(mapReady && map.current) ? null : <MapSourceWithMap map={map.current} />;
});
