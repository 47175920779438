import { create } from 'zustand';
import { MapMarkerFeature } from '/entities/Marker';

/**
 * Хранилище маркеров находящихся на карте maplibre
 */

export type MarkerFeatureStore = {
  markersFeature: MapMarkerFeature[];
  selectedMarker: MapMarkerFeature | null | undefined;
  visitedMarkers: MapMarkerFeature[];
  setMarkersFeature: (markersFeature: MapMarkerFeature[]) => void;
  setSelected: (selectedMarker: MapMarkerFeature | null) => void;
  setSelectedByLink: (link: string) => void;
  setVisited: (markerFeature: MapMarkerFeature) => void;
  setVisitedByLink: (link: string) => void;
};

export const useMarkerFeatureStore = create<MarkerFeatureStore>()((set, get) => ({
  markersFeature: [],
  selectedMarker: undefined,
  visitedMarkers: [],
  setMarkersFeature: (markersFeature) => set({ markersFeature }),
  setSelected: (selectedMarker) => set({ selectedMarker }),
  setSelectedByLink: (link) =>
    set({ selectedMarker: get().markersFeature.find((f) => f.properties.link === link) || null }),
  setVisited: (markerFeature) => {
    markerFeature.properties.visited = true;
    set((state) => ({
      markersFeature: state.markersFeature.map((f) => {
        if (markerFeature.id === f.id) {
          f.properties.visited = true;
        }
        return f;
      }),
      visitedMarkers: [markerFeature, ...state.visitedMarkers],
    }));
  },
  setVisitedByLink: (link) => {
    const markerFeature = get().markersFeature.find((f) => f.properties.link === link);
    if (markerFeature) {
      get().setVisited(markerFeature);
    }
  },
}));
