import { create } from 'zustand';

export type AppStore = {
  isClient: boolean;
  isWeb: boolean;
  setClient: () => void;
  setWeb: () => void;
};

export const useAppStore = create<AppStore>()((set, get) => ({
  isClient: false,
  isWeb: true,
  setClient: () => set({ isClient: true, isWeb: false }),
  setWeb: () => set({ isClient: false, isWeb: true }),
}));
