import { Nav } from 'react-bootstrap';
import s from './LinkItem.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { Icon } from '/shared/ui/Icon';

interface LinkItemProps {
  buttonName: string;
  className?: string;
  url?: string;
  SvgIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const LinkItem = ({ className, url, SvgIcon, buttonName }: LinkItemProps) => {
  return (
    <Nav.Item className={classNames(s.navItem, {}, [className])}>
      <Nav.Link
        href={url ?? ''}
        active={!!url}
        className={classNames(s.link, { [s.linkDisabled]: !url })}
        disabled={!url}
      >
        {SvgIcon && <Icon Svg={SvgIcon} />}
        {buttonName}
      </Nav.Link>
    </Nav.Item>
  );
};
