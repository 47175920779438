import { memo } from 'react';
import s from './MarkersList.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { MarkersListItem } from '../MarkersListItem/MarkersListItem';
import { IMarker } from '../../model/types/marker';
import { Stack } from 'react-bootstrap';

interface MarkersListProps {
  markerFeatures: IMarker[];
  className?: string;
  onClick?: (marker: IMarker) => void;
  onMouseEnter?: (marker: IMarker) => void;
  selectedMarker?: IMarker;
}

export const MarkersList = memo((props: MarkersListProps) => {
  const { className, markerFeatures, onClick, onMouseEnter, selectedMarker } = props;

  return (
    <Stack gap={0} className={classNames(s.MarkersList, {}, [className])} direction='vertical'>
      {markerFeatures.length ? (
        markerFeatures.map((m) => (
          <MarkersListItem
            markerFeature={m}
            key={m.number}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            selected={selectedMarker?.number === m.number}
          />
        ))
      ) : (
        <p className={s.empty}>пусто</p>
      )}
    </Stack>
  );
});
