import { Accordion, ListGroup, useAccordionButton } from 'react-bootstrap';
import s from './FilterCheckboxList.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { memo, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { FilterCheckboxItem } from '../model/types/filterCheckbox';
import { ListGroupItem } from './ListGroupItem/ListGroupItem';
import { TextButton } from '/shared/ui/TextButton/';

interface FilterCheckboxListProps {
  className?: string;
  title: string;
  items: FilterCheckboxItem[];
  eventKey: string;
  changed?: boolean;
  onCheckClick?: (item: FilterCheckboxItem) => void;
  onReset?: () => void;
}

function CustomToggle({ children, eventKey }: PropsWithChildren<{ eventKey: string }>) {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.log('totally custom!'));

  return (
    <button type='button' style={{ backgroundColor: 'pink' }} onClick={decoratedOnClick}>
      {children}
    </button>
  );
}

export const FilterCheckboxList = memo((props: FilterCheckboxListProps) => {
  const { className, title, items, eventKey, onCheckClick, changed, onReset } = props;

  const [isShowAll, setIsShowAll] = useState(false);

  const onShowAll = useCallback(() => setIsShowAll(true), []);
  const onHideAll = useCallback(() => setIsShowAll(false), []);

  const list = useMemo(
    () => items.map((t) => <ListGroupItem key={t.id} item={t} onCheck={onCheckClick} />),
    [items],
  );
  const listMin = useMemo(() => list.slice(0, 4), [list]);

  return (
    <Accordion.Item
      className={classNames(s.FilterCheckboxList, {}, [className])}
      eventKey={eventKey}
    >
      <Accordion.Header className={s.title}>
        {title} {changed && <span className={s.mark}></span>}
      </Accordion.Header>
      <Accordion.Body className={s.body}>
        <ListGroup variant='flush' className={s.listGroup}>
          {isShowAll ? list : listMin}
        </ListGroup>
        <div className={s.footer}>
          {items.length > 4 ? (
            !isShowAll ? (
              <TextButton onClick={onShowAll}>Показать все</TextButton>
            ) : (
              <TextButton onClick={onHideAll}>Свернуть</TextButton>
            )
          ) : (
            <div></div>
          )}
          <TextButton variant='danger' onClick={onReset}>
            Сбросить
          </TextButton>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
});
