import { useEffect } from 'react';
import { useProjectStore } from '../store/projectStore';
import { useGetProjects } from '../../api/useGetProjects';
import { getFnSetProjects } from '../selector/project';

export const useGetProjectsService = () => {
  const setProjects = useProjectStore(getFnSetProjects);
  const [{ data }, fetchProjects] = useGetProjects({ useCache: true });

  useEffect(() => {
    if (data?.data) {
      setProjects(data.data);
    }
  }, [data?.data]);

  return fetchProjects;
};
