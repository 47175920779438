import React, { useEffect } from 'react';
import s from './ClientMainPage.module.scss';
import { Outlet } from 'react-router-dom';
import { MapProvider } from 'react-map-gl';
import { MapGL } from '/widgets/MapGL';
import { MainLayout } from '/shared/layouts/MainLayout';
import { ClientHeader } from '/features/ClientHeader';
import { getAppIsClient, getFnAppSetClient, useAppStore } from '/features/App';
import { getFnSetSearchFilterInMeta, useSearchFilterStore } from '/features/SearchFilter';

const ClientMainPage = () => {
  const setClient = useAppStore(getFnAppSetClient);
  const isClient = useAppStore(getAppIsClient);

  const setInMeta = useSearchFilterStore(getFnSetSearchFilterInMeta);

  useEffect(() => setClient(), []);

  useEffect(() => {
    if (isClient) {
      setInMeta(true);
    }
  }, [isClient]);

  return (
    <MainLayout
      content={
        <main className={s.ClientMainPage}>
          <MapProvider>
            <MapGL />
            <Outlet />
          </MapProvider>
        </main>
      }
      header={<ClientHeader />}
    />
  );
};

export default ClientMainPage;
