import { create } from 'zustand';
import { FilterCheckboxItem } from '/entities/FilterCheckboxList';

export type SearchFilter = {
  tagFilterItems: FilterCheckboxItem[] | null;
  projectFilterItems: FilterCheckboxItem[] | null;
  inMeta: boolean | null;
  setTagFilterItems: (tagFilterItems: FilterCheckboxItem[]) => void;
  setProjectFilterItems: (projectFilterItems: FilterCheckboxItem[]) => void;
  toggleTagFilterItem: (tagFilterItems: FilterCheckboxItem) => void;
  resetTagFilterItems: () => void;
  toggleProjectFilterItem: (projectFilterItems: FilterCheckboxItem) => void;
  resetProjectFilterItems: () => void;
  setInMeta: (inMeta: boolean) => void;
};

export const useSearchFilterStore = create<SearchFilter>()((set, get) => ({
  tagFilterItems: null,
  projectFilterItems: null,
  inMeta: null,
  setTagFilterItems: (tagFilterItems) => set({ tagFilterItems }),
  setProjectFilterItems: (projectFilterItems) => set({ projectFilterItems }),
  toggleTagFilterItem: (tagFilterItem) =>
    set({
      tagFilterItems: get().tagFilterItems?.map((t) => {
        if (t.id === tagFilterItem.id) tagFilterItem.checked = !t.checked;
        return t;
      }),
    }),
  resetTagFilterItems: () =>
    set({ tagFilterItems: get().tagFilterItems?.map((t) => ({ ...t, checked: false })) }),
  toggleProjectFilterItem: (projectFilterItem) =>
    set({
      projectFilterItems: get().projectFilterItems?.map((t) => {
        if (t.id === projectFilterItem.id) projectFilterItem.checked = !t.checked;
        return t;
      }),
    }),
  resetProjectFilterItems: () =>
    set({ projectFilterItems: get().projectFilterItems?.map((t) => ({ ...t, checked: false })) }),
  setInMeta: (inMeta) => set({ inMeta }),
}));
