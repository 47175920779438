import maplibregl from 'maplibre-gl';
import { Protocol } from 'pmtiles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Map, { MapRef, ViewStateChangeEvent } from 'react-map-gl/maplibre';
import { MapSource } from '../../../features/MapSource/ui/MapSource';
import { clusterLayerId, unclusteredPointLayerId } from '../../../entities/MapMarkersLayer';
import { useMapStore } from '../model/store/mapStore';
import { FLY_DURATION, MAP_ID } from '../model/const/map';
import { initialViewState } from '../model/const/initialViewState';
import { mapStyle } from '../model/const/mapStyle';
import { MapViewStateSave } from './MapViewStateSave';
import { MapControl } from '/features/MapControl';
import { MapCornerLogo } from '/features/MapCornerLogo';
import {
  getFnMapFlyTo,
  getFnMapLonLat,
  getFnMapMain,
  getFnMapReady,
  getFnMapZoom,
  getMapFlyTo,
  getMapInitLat,
  getMapInitLon,
  getMapInitZoom,
} from '../model/selectors/mapSelectors';
import { Search } from '/features/Search';

export const MapGL = () => {
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>(null!);

  const initLat = useMapStore(getMapInitLat);
  const initLon = useMapStore(getMapInitLon);
  const initZoom = useMapStore(getMapInitZoom);
  const setLonLat = useMapStore(getFnMapLonLat);
  const setZoom = useMapStore(getFnMapZoom);
  const setMainMap = useMapStore(getFnMapMain);
  const setMapOnReady = useMapStore(getFnMapReady);

  const onMapLoad = useCallback(() => {
    setMainMap(mapRef.current);
    setMapOnReady();
  }, []);

  // центровка карты при открытии с адреса с маркером
  useEffect(() => {
    if (initLat && initLon) {
      mapRef.current.setCenter([initLon, initLat]);
    }
  }, [initLat, initLon, mapRef.current]);

  // центровка зума
  useEffect(() => {
    if (initZoom) {
      mapRef.current.setZoom(initZoom);
    }
  }, [initZoom, mapRef.current]);

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);
  const onZoomEnd = useCallback((e: ViewStateChangeEvent) => setZoom(e.viewState.zoom), []);
  const onMoveEnd = useCallback((e: ViewStateChangeEvent) => {
    setLonLat(e.viewState.longitude, e.viewState.latitude);
    // const bb = mapRef.current.getBounds();
    // console.log(bb._ne, bb._sw);
  }, []);

  // init
  useEffect(() => {
    let protocol = new Protocol();
    maplibregl.addProtocol('pmtiles', protocol.tile);

    return () => {
      maplibregl.removeProtocol('pmtiles');
    };
  }, []);

  const flyTo = useMapStore(getMapFlyTo);
  const setFlyTo = useMapStore(getFnMapFlyTo);

  //fly to
  useEffect(() => {
    if (flyTo) {
      mapRef.current?.flyTo({ center: flyTo, duration: FLY_DURATION });
      setTimeout(() => setFlyTo(null), FLY_DURATION);
    }
  }, [flyTo, setFlyTo]);

  return (
    <Map
      initialViewState={initialViewState}
      interactiveLayerIds={[clusterLayerId, unclusteredPointLayerId]}
      onLoad={onMapLoad}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={mapRef}
      id={MAP_ID}
      dragRotate={false}
      keyboard={false}
      attributionControl={false}
      cursor={cursor}
      mapStyle={mapStyle}
      mapLib={maplibregl}
      touchPitch={false}
      touchZoomRotate={true}
      minPitch={0}
      maxPitch={0}
      onZoomEnd={onZoomEnd}
      onMoveEnd={onMoveEnd}
      // перевод locale={}
    >
      <MapControl />

      <MapCornerLogo />
      <MapSource />

      <MapViewStateSave />

      <Search />
    </Map>
  );
};
