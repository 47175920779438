import { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import s from './InMeta.module.scss';
import { useSearchFilterStore } from '../../model/store/searchFilter';
import {
  getFnSetSearchFilterInMeta,
  getSearchFilterInMeta,
} from '../../model/selector/searchFilter';

export const InMeta = () => {
  const inMeta = useSearchFilterStore(getSearchFilterInMeta);
  const setInMeta = useSearchFilterStore(getFnSetSearchFilterInMeta);

  const onChangeHandler = useCallback(() => {
    setInMeta(!inMeta);
  }, [inMeta]);

  return (
    <div className={s.InMeta}>
      <Form.Check
        type='checkbox'
        id='InMeta'
        label='Есть в метавселенной'
        checked={inMeta || false}
        onChange={onChangeHandler}
      />
    </div>
  );
};
