import { PropsWithChildren } from 'react';
import s from './MobileCard.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { Button } from 'react-bootstrap';
import { Icon } from '/shared/ui/Icon';
import closeIcon from '/shared/assets/icons/close.svg?react';

interface MobileCardProps extends PropsWithChildren {
  className?: string;
  onClose?: () => void;
}

export const MobileCard = (props: MobileCardProps) => {
  const { children, onClose, className } = props;

  return (
    <>
      <Button className={s.close} onClick={onClose}>
        <Icon Svg={closeIcon} />
      </Button>
      <div className={classNames(s.MobileCard, {}, [className])}>
        <div className={s.content}>{children}</div>
      </div>
    </>
  );
};
