import { Button, ButtonProps } from 'react-bootstrap';
import s from './CloseButton.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';

interface CloseButtonProps extends ButtonProps {
  fillColor?: string;
  className?: string;
}

export const CloseButton = (props: CloseButtonProps) => {
  const { className, fillColor = 'white', ...other } = props;
  return (
    <Button variant='light' className={classNames(s.CloseButton, {}, [className])} {...other}>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={s.svg}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.43333 6.73763L13.1559 2.01502C13.6003 1.57065 13.5422 0.814211 13.1009 0.372977C12.6258 -0.102199 11.9033 -0.126407 11.4589 0.31796L6.73627 5.04058L2.01502 0.319319C1.57065 -0.125048 0.848152 -0.10084 0.372977 0.374336C-0.102199 0.849511 -0.126407 1.57201 0.31796 2.01637L5.03922 6.73763L0.317994 11.4589C-0.126372 11.9032 -0.102164 12.6257 0.373011 13.1009C0.848187 13.5761 1.57068 13.6003 2.01505 13.1559L6.73628 8.43469L11.4589 13.1573C11.9032 13.6016 12.6597 13.5435 13.1009 13.1023C13.5761 12.6271 13.6003 11.9046 13.1559 11.4602L8.43333 6.73763Z'
          fill={fillColor}
        />
      </svg>
    </Button>
  );
};
