import React, { useEffect } from 'react';
import s from './MainPage.module.scss';
import { Outlet } from 'react-router-dom';
import { MapProvider } from 'react-map-gl';
import { MapGL } from '/widgets/MapGL';
import { MainLayout } from '/shared/layouts/MainLayout';
import { isMobile } from 'react-device-detect';
import { MobileHeader } from '/features/MobileHeader/';
import { HeadBar } from '/features/HeadBar/';
import { getFnAppSetWeb, useAppStore } from '/features/App';

const MainPage = () => {
  const setWeb = useAppStore(getFnAppSetWeb);

  useEffect(() => setWeb(), []);

  return (
    <MainLayout
      content={
        <main className={s.MainPage}>
          <MapProvider>
            <MapGL />
            <Outlet />
          </MapProvider>
        </main>
      }
      mobileHeader={isMobile ? <MobileHeader /> : undefined}
      header={!isMobile ? <HeadBar /> : undefined}
    />
  );
};

export default MainPage;
