import { Form, FormControlProps } from 'react-bootstrap';
import s from './SearchField.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { Icon } from '/shared/ui/Icon';
import searchIcon from '/shared/assets/icons/search.svg?react';
import { CloseButton } from '/shared/ui/CloseButton/CloseButton';
import { ChangeEvent, useCallback } from 'react';

interface SearchFieldProps extends Omit<FormControlProps, 'onChange'> {
  value: string | undefined;
  onChange: (value: string) => void;
  onReset: () => void;
  onSubmit?: () => void;
  className?: string;
}

export const SearchField = (props: SearchFieldProps) => {
  const { className, value, onChange, onSubmit, onReset, ...other } = props;

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange],
  );

  return (
    <div className={classNames(s.SearchField, {}, [className])}>
      <Icon Svg={searchIcon} className={s.icon} />
      <Form.Control
        value={value}
        type='search'
        placeholder='Поиск'
        className={s.input}
        onSubmit={onSubmit}
        onChange={onChangeHandler}
        {...other}
      />
      {value && (
        <CloseButton
          onClick={onReset}
          className={s.closeButton}
          fillColor='#878787'
          title='очистить'
        />
      )}
    </div>
  );
};
