import { useCallback, useEffect, useMemo } from 'react';
import {
  getFnSearchSetFieldValue,
  getFnSearchSetIsBackBtn,
  getFnSearchSetText,
  getSearchFieldValue,
  getSearchIsBackBtn,
  getSearchText,
} from '../model/selectors/search';
import { useSearchStore } from '../model/store/searchStore';
import s from './Search.module.scss';
import { SearchField } from '/entities/SearchField';
import { classNames } from '/shared/lib/classNames/classNames';
import { useNavigate } from 'react-router-dom';
import { useSearchService } from '../model/services/useSearchService';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Icon } from '/shared/ui/Icon';
import backIcon from '/shared/assets/icons/arrow back.svg?react';
import { useRouterSearch } from '/shared/hooks/router/useRouterSearch';
import { useSearchResultFilterService } from '../model/services/useSearchResultFilterService';

interface SearchProps {
  className?: string;
}

export const Search = ({ className }: SearchProps) => {
  const navigate = useNavigate();

  const getRouteSearch = useRouterSearch();

  const searchText = useSearchStore(getSearchText);
  const setSearchText = useSearchStore(getFnSearchSetText);
  const fieldValue = useSearchStore(getSearchFieldValue);
  const setFieldValue = useSearchStore(getFnSearchSetFieldValue);
  const isBackBtn = useSearchStore(getSearchIsBackBtn);
  const setIsBackBtn = useSearchStore(getFnSearchSetIsBackBtn);

  useSearchService();
  useSearchResultFilterService();

  const onResetHandler = useCallback(() => {
    setFieldValue('');
    setSearchText('');
  }, [setSearchText]);

  const onChangeHandler = useCallback((value: string) => setFieldValue(value), []);

  // открытие sidebar
  const onFocusHandler = useCallback(() => navigate(getRouteSearch()), []);

  useEffect(() => setFieldValue(searchText || ''), [searchText]);

  const onBackClickHandler = useCallback(() => {
    setIsBackBtn(false);
    navigate(-1);
  }, []);

  const backBtn = useMemo(
    () => (
      <Button className={s.back} onClick={onBackClickHandler}>
        <Icon Svg={backIcon} />
      </Button>
    ),
    [],
  );

  return (
    <div className={classNames(s.Search, { [s.mobile]: isMobile }, [className])}>
      {isBackBtn && backBtn}
      <SearchField
        value={fieldValue}
        onChange={onChangeHandler}
        onReset={onResetHandler}
        onFocus={onFocusHandler}
        className={s.field}
      />
    </div>
  );
};
