import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { SideBar } from '/entities/SideBar';
import { CardSerchFeature } from './CardSerchFeature/CardSerchFeature';
import { MobileCard } from '/entities/MobileCard';
import { getFnSearchSetIsBackBtn, useSearchStore } from '/features/Search';

export const CardSearch = () => {
  const navigate = useNavigate();

  const setIsBackBtn = useSearchStore(getFnSearchSetIsBackBtn);

  const onClose = useCallback(() => {
    setIsBackBtn(false);
    navigate('../');
  }, []);

  if (isMobile) {
    return (
      <MobileCard onClose={onClose}>
        <CardSerchFeature />
      </MobileCard>
    );
  }

  return (
    <SideBar onClose={onClose}>
      <CardSerchFeature />
    </SideBar>
  );
};
