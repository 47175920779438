import { create } from 'zustand';
import { IProject } from '../type/project';

export type ProjectStore = {
  projects: IProject[] | null;
  setProjects: (projects: IProject[]) => void;
};

export const useProjectStore = create<ProjectStore>()((set) => ({
  projects: null,
  setProjects: (projects) => set({ projects }),
}));
