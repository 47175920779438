import { Nav } from 'react-bootstrap';
import { LinkItem } from '/entities/CardLinks';
import clienyIcon from '/shared/assets/icons/client.svg?react';
import { memo } from 'react';

interface CardClientLinksProps {
  className?: string;
  clientrURL?: string;
}

export const CardClientLinks = memo(({ className, clientrURL }: CardClientLinksProps) => {
  return (
    <Nav variant='pills' navbar={false} className={className}>
      <LinkItem buttonName='Клиент' SvgIcon={clienyIcon} url={clientrURL} />
    </Nav>
  );
});
