import {
  getSearchFilterTagItems,
  getFnSetSearchFilterTagToggle,
  getFnSetSearchFilterTagReset,
  getSearchFilterTagItemsChanged,
} from '../../model/selector/searchFilter';
import { useSearchFilterStore } from '../../model/store/searchFilter';
import { FilterCheckboxList } from '/entities/FilterCheckboxList';

export const TagFIlter = () => {
  const tagFilterItems = useSearchFilterStore(getSearchFilterTagItems);
  const toggleTagFilterItem = useSearchFilterStore(getFnSetSearchFilterTagToggle);
  const resetTagFilterItems = useSearchFilterStore(getFnSetSearchFilterTagReset);
  const searchFilterTagItemsChanged = useSearchFilterStore(getSearchFilterTagItemsChanged);

  return (
    <FilterCheckboxList
      eventKey='0'
      title='Теги'
      changed={searchFilterTagItemsChanged}
      items={tagFilterItems || []}
      onCheckClick={toggleTagFilterItem}
      onReset={resetTagFilterItems}
    />
  );
};
