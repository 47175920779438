import { create } from 'zustand';
import { IMarker } from '/entities/Marker';

export type SearchStore = {
  errorText: string | undefined;
  text: string | null;
  isBarOpen: boolean;
  paramLimit?: number;
  paramSkip?: number;
  fieldValue: string;
  rawResult: IMarker[] | null;
  filteredResult: IMarker[] | null;
  isBackBtn: boolean;
  setErrorText: (errorText: string | undefined) => void;
  setText: (text: string) => void;
  setIsBarOpen: (isBarOpen: boolean) => void;
  setParamLimit: (limit?: number) => void;
  setParamSkip: (skip?: number) => void;
  setFieldValue: (fieldValue: string) => void;
  setRawResult: (rawResult: IMarker[]) => void;
  setFilteredResult: (filteredResult: IMarker[]) => void;
  setIsBackBtn: (isBackBtn: boolean) => void;
};

export const useSearchStore = create<SearchStore>()((set, get) => ({
  errorText: undefined,
  text: null,
  isBarOpen: true,
  paramLimit: undefined,
  paramSkip: undefined,
  fieldValue: '',
  rawResult: null,
  filteredResult: null,
  isBackBtn: false,
  setErrorText: (errorText: string | undefined) => set(() => ({ errorText })),
  setText: (text) => set({ text }),
  setIsBarOpen: (isBarOpen) => set({ isBarOpen }),
  setParamLimit: (paramLimit) => set({ paramLimit }),
  setParamSkip: (paramSkip) => set({ paramSkip }),
  setFieldValue: (fieldValue) => set({ fieldValue }),
  setRawResult: (rawResult) => set({ rawResult }),
  setFilteredResult: (filteredResult) => set({ filteredResult }),
  setIsBackBtn: (isBackBtn) => {
    if (get().isBackBtn !== isBackBtn) {
      set({ isBackBtn });
    }
  },
}));
