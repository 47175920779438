import { Suspense } from 'react';
import './App.scss';
import { AppRouter } from './providers/router';
import { useFingerprint } from '/shared/hooks/useFingerprint';

const App = () => {
  const fp = useFingerprint();

  return (
    <Suspense fallback=''>
      <AppRouter />
    </Suspense>
  );
};

export default App;
