import React, { memo, useCallback, useEffect } from 'react';
import { MapMarkerFeature } from '/entities/Marker';
import { MapGeoJSONFeature } from 'react-map-gl/maplibre';
import { useMarkerFeatureStore } from '../model/store/markerFeatureStore';
import {
  getFnMarkerFeatureList,
  getFnMarkerFeatureSelected,
  getFnMarkerFeatureVisited,
  getMarkerFeatureList,
  getMarkerFeatureSelected,
  getMarkerFeatureVisitedList,
} from '../model/selectors/markerFeature';
import { filterMarkerFeatures } from '../lib/filterMarkerFeatures';
import { updateMarkerFeatures } from '../lib/updateMarkerFeatures';
import { updateVisited } from '../lib/updateVisited';
import { useNavigate } from 'react-router-dom';
import { MarkersList } from './MarkersList';
import { useRouterMarker } from '/shared/hooks/router/useRouterMarker';

interface MapMarkersProps {
  mapFeatures: MapGeoJSONFeature[] | null;
}

export const MapMarkers = memo((props: MapMarkersProps) => {
  const { mapFeatures } = props;

  const getRouteMarker = useRouterMarker();

  const navigate = useNavigate();

  const markersFeature = useMarkerFeatureStore(getMarkerFeatureList);
  const selectedMarker = useMarkerFeatureStore(getMarkerFeatureSelected);
  const visitedMarkers = useMarkerFeatureStore(getMarkerFeatureVisitedList);
  const setMarkerFeature = useMarkerFeatureStore(getFnMarkerFeatureList);
  // const setVisited = useMarkerFeatureStore(getFnMarkerFeatureVisited);

  useEffect(() => {
    if (mapFeatures) {
      // console.log('MapMarkers exec', mapFeatures);
      const filteredMapMarkers = filterMarkerFeatures(mapFeatures) as MapMarkerFeature[];
      const updateValue = updateMarkerFeatures(markersFeature, filteredMapMarkers);
      if (updateValue) {
        // add selected
        if (selectedMarker) {
          if (!updateValue.find((m) => m.id === selectedMarker.id)) {
            updateValue.push(selectedMarker);
          }
        }

        const visitedUpdateValue = updateVisited(updateValue, visitedMarkers);
        // console.log('setMarkerFeature', visitedUpdateValue);
        setMarkerFeature(visitedUpdateValue);
      }
    } else if (selectedMarker) {
      setMarkerFeature([selectedMarker]);
    }
  }, [mapFeatures, selectedMarker]);

  const onMarkerSelect = useCallback((marker: MapMarkerFeature) => {
    // console.log('open', marker.properties.link, marker.id);
    navigate(getRouteMarker(`${marker.properties.link}`));
    // setVisited(marker);
  }, []);

  return (
    <MarkersList
      markersFeature={markersFeature}
      selectedMarker={selectedMarker || null}
      onSelect={onMarkerSelect}
    />
  );
});
