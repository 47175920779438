import { Form } from 'react-bootstrap';
import s from './VisitField.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { Icon } from '/shared/ui/Icon';
import { useCallback, ChangeEvent } from 'react';
import visitIcon from '/shared/assets/icons/arrow to right 2.svg?react';
import { IconButton } from '/shared/ui/IconButton/IconButton';

interface VisitFieldProps {
  value: string | undefined;
  onChange: (value: string) => void;
  onSubmit?: () => void;
  className?: string;
}

export const VisitField = (props: VisitFieldProps) => {
  const { className, value, onChange, onSubmit, ...other } = props;

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange],
  );

  return (
    <div className={classNames(s.VisitField, {}, [className])}>
      <Form.Control
        value={value}
        type='input'
        placeholder='Посетить'
        className={s.input}
        onSubmit={onSubmit}
        onChange={onChangeHandler}
        {...other}
      />
      {value && (
        <IconButton className={s.visitIconBtn} onClick={onSubmit}>
          <Icon Svg={visitIcon} className={s.icon} />
        </IconButton>
      )}
    </div>
  );
};
