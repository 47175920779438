import { Accordion } from 'react-bootstrap';
import { useInitFiltesService } from '../model/services/useInitFiltesService';
import { TagFIlter } from './TagFIlter/TagFIlter';
import { ProjectFilter } from './ProjectFilter/ProjectFilter';
import { InMeta } from './InMeta/InMeta';
import s from './SearchFilter.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';

interface SearchFilterProps {
  className?: string;
}

export const SearchFilter = ({ className }: SearchFilterProps) => {
  useInitFiltesService();

  return (
    <Accordion className={classNames(s.SearchFilter, {}, [className])} flush>
      <InMeta />
      <TagFIlter />
      <ProjectFilter />
    </Accordion>
  );
};
