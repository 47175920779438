import { useEffect } from 'react';
import {
  getFnSearchSetFilteredResult,
  getSearchFilteredResult,
  getSearchRawResult,
} from '../selectors/search';
import { useSearchStore } from '../store/searchStore';
import { mapMarkersToFeatureCollection } from '../../lib/mapMarkersToFeatureCollection';
import { getFnClusterSourceCurrentGeojson, useClusterSourceStore } from '/features/MapSource';
import {
  getSearchFilterInMeta,
  getSearchFilterProjectItems,
  getSearchFilterTagItems,
  useSearchFilterStore,
} from '/features/SearchFilter';

export const useSearchResultFilterService = () => {
  const setClusterSourceCurrentGeojson = useClusterSourceStore(getFnClusterSourceCurrentGeojson);

  const rawResult = useSearchStore(getSearchRawResult);
  const searchFilteredResult = useSearchStore(getSearchFilteredResult);
  const setFilteredResult = useSearchStore(getFnSearchSetFilteredResult);

  const inMeta = useSearchFilterStore(getSearchFilterInMeta);
  const projectFilterItems = useSearchFilterStore(getSearchFilterProjectItems);
  const tagFilterItems = useSearchFilterStore(getSearchFilterTagItems);

  useEffect(() => {
    if (rawResult !== null) {
      let results = rawResult;

      if (inMeta) {
        results = results.filter((m) => m.metaClientUrl);
      }

      const checkedTags = tagFilterItems?.filter((t) => t.checked);
      if (tagFilterItems && checkedTags?.length) {
        results = results.filter((m) => {
          if (!m.tags) return false;

          for (let i = 0; i < m.tags.length; i++) {
            const tag = m.tags[i];
            if (checkedTags.find((t) => t.id === tag._id)) {
              return true;
            }
          }
          return false;
        });
      }

      const checkedProjects = projectFilterItems?.filter((t) => t.checked);
      if (projectFilterItems && checkedProjects?.length) {
        results = results.filter((m) => {
          if (!m.project) return false;

          return checkedProjects.find((p) => p.id === m.project?._id);
        });
      }

      setFilteredResult(results);
    }
  }, [rawResult, inMeta, projectFilterItems, tagFilterItems]);

  // перенос на карту
  useEffect(() => {
    if (searchFilteredResult) {
      const features = mapMarkersToFeatureCollection(searchFilteredResult);
      // console.log('setClusterSourceCurrentGeojson', features.features);
      setClusterSourceCurrentGeojson(features);
    }
  }, [searchFilteredResult]);

  return searchFilteredResult;
};
