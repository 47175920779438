import React, { PropsWithChildren } from 'react';
import s from './SideBar.module.scss';
import { CloseButton } from '/shared/ui/CloseButton/CloseButton';

interface SideBarProps {
  onClose?: () => void;
}

export const SideBar = (props: PropsWithChildren<SideBarProps>) => {
  const { children, onClose } = props;

  return (
    <aside className={s.SideBar}>
      {children}
      <CloseButton onClick={onClose} className={s.close} title='закрыть' fillColor='#7C6BFF' />
    </aside>
  );
};
