import { useCallback, useEffect } from 'react';
import { useCardStore } from '../model/store/cardStore';
import { useGetCardService } from '../model/services/useGetCardService';
import { useCardLikedService } from '../model/services/useCardLikedService';
import {
  getCard,
  getCardErrorText,
  getCardExtraInfo,
  getCardLoading,
} from '../model/selectors/card';
import { useCardExtraInfoService } from '../model/services/useCardExtraInfoService';
import { isMobile } from 'react-device-detect';
import { getFnMapInitLonLat, getFnMapInitZoom, getMapInitLat, useMapStore } from '/widgets/MapGL/';
import { Card } from '/entities/Card';
import { getFnMarkerFeatureVisitedByLink, useMarkerFeatureStore } from '/features/MapMarkers';
import { getAppIsClient, useAppStore } from '/features/App';
import { CardClientLinks } from './CardClientLinks/CardClientLinks';

interface CardFeatureProps {
  link: string;
}

export const CardFeature = ({ link }: CardFeatureProps) => {
  const setInitLonLat = useMapStore(getFnMapInitLonLat);
  const initLat = useMapStore(getMapInitLat);
  const setInitZoom = useMapStore(getFnMapInitZoom);

  const card = useCardStore(getCard);
  const loading = useCardStore(getCardLoading);
  const errorText = useCardStore(getCardErrorText);
  const extraInfo = useCardStore(getCardExtraInfo);

  const setVisitedByLink = useMarkerFeatureStore(getFnMarkerFeatureVisitedByLink);

  const isClient = useAppStore(getAppIsClient);

  useGetCardService(link);
  const executeLiked = useCardLikedService(link);
  const executeGetExtraInfo = useCardExtraInfoService(link);

  const onLikeClick = useCallback(() => {
    executeLiked().then(() => {
      executeGetExtraInfo();
    });
  }, [link]);

  useEffect(() => {
    if (card && !initLat) {
      setInitLonLat(card.lon, card.lat);
      setInitZoom(13);
    }
  }, [card?.lon, card?.lat, initLat]);

  useEffect(() => {
    return () => {
      setVisitedByLink(link);
    };
  }, [link]);

  const onShareClick = useCallback(() => {
    if (isMobile) {
      if (navigator.share) {
        try {
          navigator.share({
            title: card?.name,
            url: window.location.href,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // TODO toasts
      window.navigator.clipboard.writeText(window.location.href);
    }
  }, [link]);

  return (
    <Card
      card={card || undefined}
      loading={loading}
      errorText={errorText}
      onLikeClick={onLikeClick}
      onShareClick={onShareClick}
      liked={extraInfo?.liked}
      links={isClient ? <CardClientLinks clientrURL={card?.metaClientUrl} /> : undefined}
    />
  );
};
