import { memoize } from 'proxy-memoize';
import { SearchFilter } from '../store/searchFilter';

export const getSearchFilterTagItems = memoize((s: SearchFilter) => s.tagFilterItems);
export const getSearchFilterTagItemsChanged = memoize((s: SearchFilter) => {
  return Boolean(getSearchFilterTagItems(s)?.filter((t) => t.checked).length);
});
export const getSearchFilterProjectItems = memoize((s: SearchFilter) => s.projectFilterItems);
export const getSearchFilterProjectItemsChanged = memoize((s: SearchFilter) => {
  return Boolean(getSearchFilterProjectItems(s)?.filter((t) => t.checked).length);
});
export const getSearchFilterInMeta = memoize((s: SearchFilter) => s.inMeta);

export const getFnSetSearchFilterTagItems = memoize((s: SearchFilter) => s.setTagFilterItems);
export const getFnSetSearchFilterTagReset = memoize((s: SearchFilter) => s.resetTagFilterItems);
export const getFnSetSearchFilterTagToggle = memoize((s: SearchFilter) => s.toggleTagFilterItem);
export const getFnSetSearchFilterProjectItems = memoize(
  (s: SearchFilter) => s.setProjectFilterItems,
);
export const getFnSetSearchFilterProjectReset = memoize(
  (s: SearchFilter) => s.resetProjectFilterItems,
);
export const getFnSetSearchFilterProjectToggle = memoize(
  (s: SearchFilter) => s.toggleProjectFilterItem,
);
export const getFnSetSearchFilterInMeta = memoize((s: SearchFilter) => s.setInMeta);
