import { create } from 'zustand';
import { ITag } from '../types/tag';

export type TagStore = {
  tags: ITag[] | null;
  setTags: (tags: ITag[]) => void;
};

export const useTagStore = create<TagStore>()((set) => ({
  tags: null,
  setTags: (tags) => set({ tags }),
}));
