import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SideBar } from '/entities/SideBar/';
import { isMobile } from 'react-device-detect';
import { CardDrawer } from '/shared/ui/CardDrawer/';
import {
  getFnMarkerFeatureSelectedByLink,
  getMarkerFeatureList,
  getMarkerFeatureSelected,
  useMarkerFeatureStore,
} from '/features/MapMarkers/';
import { CardFeature } from '/features/CardFeature';
import { getFnMarkerFeatureSelected } from '/features/MapMarkers/';
import { getFnSearchSetIsBackBtn, useSearchStore } from '/features/Search';

export const CardSidebar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const once = useRef(false);

  const setSelectedByLink = useMarkerFeatureStore(getFnMarkerFeatureSelectedByLink);
  const selected = useMarkerFeatureStore(getMarkerFeatureSelected);
  const markersFeature = useMarkerFeatureStore(getMarkerFeatureList);
  const setSelected = useMarkerFeatureStore(getFnMarkerFeatureSelected);

  const setIsBackBtn = useSearchStore(getFnSearchSetIsBackBtn);

  const onCloseMob = useCallback(() => {
    setIsOpen(false);
    navigate('../');
    setIsBackBtn(false);
  }, []);

  // init
  useEffect(() => {
    if ((!selected || selected.properties.link !== id) && markersFeature.length && id) {
      setSelectedByLink(id);
    }
  }, [markersFeature]);

  useEffect(() => {
    return () => {
      setSelected(null);
    };
  }, []);

  if (!id) {
    return null;
  }

  if (isMobile) {
    return (
      <CardDrawer isOpen={isOpen} onClose={onCloseMob}>
        <CardFeature link={id} />
      </CardDrawer>
    );
  }

  return (
    <SideBar onClose={onCloseMob}>
      <CardFeature link={id} />
    </SideBar>
  );
};

// export default CardSidebarFeature;
