import { classNames } from '/shared/lib/classNames/classNames';
import s from './ListGroupItem.module.scss';
import { FilterCheckboxItem } from '../../model/types/filterCheckbox';
import { Form, ListGroup } from 'react-bootstrap';
import { memo, useCallback } from 'react';

interface ListGroupItemProps {
  item: FilterCheckboxItem;
  onCheck?: (item: FilterCheckboxItem) => void;
}

export const ListGroupItem = ({ item, onCheck }: ListGroupItemProps) => {
  const onCheckHandler = useCallback(() => onCheck?.(item), [item, onCheck]);

  return (
    <ListGroup.Item
      action
      variant='light'
      onClick={onCheckHandler}
      className={classNames(s.ListGroupItem, {}, [])}
    >
      <Form.Check
        type='checkbox'
        // id={item.id}
        label={item.value}
        className={s.check}
        checked={item.checked}
        onChange={() => {}}
        // onChange={onCheckHandler}
      />
    </ListGroup.Item>
  );
};
