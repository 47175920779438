import { memoize } from 'proxy-memoize';
import { MapStore } from '../store/mapStore';

export const getMapReady = memoize((s: MapStore) => s.ready);
export const getMapMain = memoize((s: MapStore) => s.map);
export const getMapLon = memoize((s: MapStore) => s.lon);
export const getMapLat = memoize((s: MapStore) => s.lat);
export const getMapZoom = memoize((s: MapStore) => s.zoom);
export const getMapInitLon = memoize((s: MapStore) => s.initLon);
export const getMapInitLat = memoize((s: MapStore) => s.initLat);
export const getMapInitZoom = memoize((s: MapStore) => s.initZoom);
export const getMapFlyTo = memoize((s: MapStore) => s.flyTo);

export const getFnMapReady = memoize((s: MapStore) => s.setReady);
export const getFnMapInitLonLat = memoize((s: MapStore) => s.setInitLonLat);
export const getFnMapInitZoom = memoize((s: MapStore) => s.setInitZoom);
export const getFnMapLonLat = memoize((s: MapStore) => s.setLonLat);
export const getFnMapZoom = memoize((s: MapStore) => s.setZoom);
export const getFnMapMain = memoize((s: MapStore) => s.setMainMap);
export const getFnMapFlyTo = memoize((s: MapStore) => s.setFlyTo);
