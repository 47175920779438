import { memoize } from 'proxy-memoize';
import { SearchStore } from '../store/searchStore';

export const getSearchText = memoize((s: SearchStore) => s.text);
export const getSearchParamLimit = memoize((s: SearchStore) => s.paramLimit);
export const getSearchParamSkip = memoize((s: SearchStore) => s.paramSkip);
export const getSearchFieldValue = memoize((s: SearchStore) => s.fieldValue);
export const getSearchRawResult = memoize((s: SearchStore) => s.rawResult);
export const getSearchFilteredResult = memoize((s: SearchStore) => s.filteredResult);
export const getSearchIsBackBtn = memoize((s: SearchStore) => s.isBackBtn);

export const getFnSearchSetText = memoize((s: SearchStore) => s.setText);
export const getFnSearchSetParamLimit = memoize((s: SearchStore) => s.setParamLimit);
export const getFnSearchSetParamSkip = memoize((s: SearchStore) => s.setParamSkip);
export const getFnSearchSetFieldValue = memoize((s: SearchStore) => s.setFieldValue);
export const getFnSearchSetRawResult = memoize((s: SearchStore) => s.setRawResult);
export const getFnSearchSetFilteredResult = memoize((s: SearchStore) => s.setFilteredResult);
export const getFnSearchSetIsBackBtn = memoize((s: SearchStore) => s.setIsBackBtn);
