import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Marker as MLMarker, MarkerProps as MLMarkerProps, PointLike } from 'react-map-gl/maplibre';
import { classNames } from '/shared/lib/classNames/classNames';
import s from './Marker.module.scss';
import { setIconColor } from '../lib/setIconColor';
import { MarkerEvent } from '../model/types/event';

const markerOffset: PointLike = [0, -22.5];

interface MarkerProps extends Omit<MLMarkerProps, 'onClick'> {
  className?: string;
  selected?: boolean;
  onClick?: (e: MarkerEvent) => void;
  onSelect?: (e: MarkerEvent) => void;
  iconLogoSrc?: string;
  svgPath?: ReactElement<React.SVGProps<SVGSVGElement>>;
  visited?: boolean;
}

export const Marker = memo((props: MarkerProps) => {
  const {
    latitude,
    longitude,
    selected = false,
    onClick,
    onSelect,
    children,
    className,
    iconLogoSrc = '/icon/custom icon logo.svg',
    svgPath,
    visited,
    ...other
  } = props;

  const [isVisited, setIsVisited] = useState(visited || false);

  const iconColor = useMemo(() => {
    return setIconColor(selected, isVisited);
  }, [selected, isVisited]);

  const CustomPin = useMemo(
    () => (
      <svg
        className={classNames(s.background, { [s.selected]: selected })}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.75 36.1742C11.4155 39.3547 14.3422 42.3072 17.4999 45C20.6576 42.3072 23.5844 39.3547 26.2499 36.1742C30.2498 31.3981 34.9999 24.2849 34.9999 17.5063C35.0147 12.8623 33.171 8.40564 29.8799 5.13135C24.8751 0.120969 17.3446 -1.37671 10.8026 1.33313C4.26056 4.04065 -0.0035988 10.4252 2.27905e-06 17.5063C2.27905e-06 24.2849 4.74994 31.3981 8.75 36.1742Z'
          fill={iconColor}
        />
      </svg>
    ),
    [selected, iconColor],
  );

  useEffect(() => {
    if (typeof visited !== 'undefined') setIsVisited(visited);
  }, [visited]);

  const onClickHandler = useCallback(
    (e: MarkerEvent) => {
      onClick?.(e);
      if (!selected) {
        // console.log(selected, marker.id);
        onSelect?.(e);
      }
    },
    [selected],
  );

  return (
    <MLMarker
      latitude={latitude}
      longitude={longitude}
      offset={markerOffset}
      onClick={onClickHandler}
      className={classNames(s.Marker, { [s.selected]: selected }, [className])}
      {...other}
    >
      {children || CustomPin}
      <div className={s.iconContainer}>
        <img src={iconLogoSrc} className={s.icon} height='30px' width='30px' />
      </div>
    </MLMarker>
  );
});
