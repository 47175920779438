import { create } from 'zustand';

export type SearchQueryParamsStore = {
  query: string | null;
  setQuery: (query: string) => void;
};

export const useSearchQueryParamsStore = create<SearchQueryParamsStore>()((set) => ({
  query: null,
  setQuery: (query) => set({ query }),
}));
