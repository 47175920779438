import { Navbar, Stack } from 'react-bootstrap';
import s from './ClientHeader.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { VisitField } from '/entities/VisitField';
import { memo, useCallback, useState } from 'react';
import { IconButton } from '/shared/ui/IconButton/IconButton';
import { Icon } from '/shared/ui/Icon';
import homeIcon from '/shared/assets/icons/home.svg?react';

interface ClientHeaderProps {
  className?: string;
}

export const ClientHeader = memo(({ className }: ClientHeaderProps) => {
  const [fieldValue, setFieldValue] = useState('');

  const onChangeHandler = useCallback((value: string) => setFieldValue(value), []);
  const onSubmitHandler = useCallback(() => {
    var readyEvent = {
      action: 'goToUrl',
      visit: fieldValue,
    };
    // @ts-ignore
    EventBridge.emitWebEvent(JSON.stringify(readyEvent));
  }, [fieldValue]);
  const onHomeHandler = useCallback(() => {
    var readyEvent = {
      action: 'navigateHome',
    };
    // @ts-ignore
    EventBridge.emitWebEvent(JSON.stringify(readyEvent));
  }, []);

  return (
    <Navbar expand='lg' className={classNames(s.ClientHeader, {}, [className])}>
      <Stack direction='horizontal' className={s.wrapper}>
        <span className={s.researchText}>Исследовать</span>
        <div className={s.visitField}>
          <VisitField onChange={onChangeHandler} value={fieldValue} onSubmit={onSubmitHandler} />
        </div>
        <IconButton className={s.homeIconBtn} onClick={onHomeHandler}>
          <Icon Svg={homeIcon} className={s.homeIcon} />
        </IconButton>
      </Stack>
    </Navbar>
  );
});
