import { useEffect } from 'react';
import { useTagStore } from '../store/tagStore';
import { useGetTags } from '../../api/useGetTags';
import { getFnSetTag } from '../selector/tag';

export const useGetTagsService = () => {
  const setTags = useTagStore(getFnSetTag);
  const [{ data }, fetchTags] = useGetTags({ useCache: true });

  useEffect(() => {
    if (data?.data) {
      setTags(data.data);
    }
  }, [data?.data]);

  return fetchTags;
};
