import { useCallback } from 'react';
import { getAppIsClient, useAppStore } from '/features/App';
import { getRouteClientSearch, getRouteWebSearch } from '/shared/const/router';

export const useRouterSearch = () => {
  const isClient = useAppStore(getAppIsClient);

  const useRouterSearchCb = useCallback(() => {
    if (isClient) {
      return getRouteClientSearch();
    } else {
      return getRouteWebSearch();
    }
  }, [isClient]);

  return useRouterSearchCb;
};
