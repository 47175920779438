import { useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useCookies } from 'react-cookie';

export const useFingerprint = () => {
  const [cookie, setCookie] = useCookies(['fp']);

  useEffect(() => {
    if (!cookie.fp) {
      const setFp = async () => {
        const fp = await FingerprintJS.load();

        const { visitorId } = await fp.get();

        // { sameSite: 'none', secure: true, partitioned: true }
        setCookie('fp', visitorId);
      };

      setFp();
    }
  }, [cookie.fp]);

  return cookie.fp;
};
