import { Nav, Stack } from 'react-bootstrap';
import s from './CardLinks.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import browserIcon from '/shared/assets/icons/browser.svg?react';
import clienyIcon from '/shared/assets/icons/client.svg?react';
import ARIcon from '/shared/assets/icons/AR.svg?react';
import { LinkItem } from './LinkItem/LinkItem';
import { memo } from 'react';

interface CardLinksProps {
  className?: string;
  browserURL?: string;
  clientrURL?: string;
  ARURL?: string;
}

export const CardLinks = memo((props: CardLinksProps) => {
  const { className, ARURL, browserURL, clientrURL } = props;

  return (
    <Stack className={classNames(s.CardLinks, {}, [className])} direction='vertical'>
      <p className={s.text}>Войти через:</p>
      <Nav variant='pills' navbar={false} className={s.nav}>
        <LinkItem buttonName='Браузер' SvgIcon={browserIcon} url={browserURL} />
        <LinkItem buttonName='Клиент' SvgIcon={clienyIcon} url={clientrURL} />
        <LinkItem buttonName='AR' SvgIcon={ARIcon} url={ARURL} />
      </Nav>
    </Stack>
  );
});
