import { Link } from 'react-router-dom';
import { IMarker } from '../../model/types/marker';
import s from './MarkersListItem.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { memo, useCallback } from 'react';
import { useRouterMarker } from '/shared/hooks/router/useRouterMarker';

interface MarkersListItemProps {
  markerFeature: IMarker;
  className?: string;
  onClick?: (marker: IMarker) => void;
  onMouseEnter?: (marker: IMarker) => void;
  selected?: boolean;
}

export const MarkersListItem = memo((props: MarkersListItemProps) => {
  const { className, markerFeature, onClick, onMouseEnter, selected } = props;

  const onClickHandler = useCallback(() => onClick?.(markerFeature), []);
  const onMouseEnterHandler = useCallback(() => onMouseEnter?.(markerFeature), []);

  const getRouteMarker = useRouterMarker();

  return (
    <Link
      to={getRouteMarker(markerFeature.link)}
      className={classNames(s.MarkersListItem, { [s.selected]: selected }, [className])}
      onClick={onClickHandler}
      onMouseEnter={onMouseEnterHandler}
    >
      <div className={s.enter}>{markerFeature.name}</div>
    </Link>
  );
});
