import { useCallback } from 'react';
import { getAppIsClient, useAppStore } from '/features/App';
import { getRouteClientMarker, getRouteWebMarker } from '/shared/const/router';

export const useRouterMarker = () => {
  const isClient = useAppStore(getAppIsClient);

  const useRouterMarkerCb = useCallback(
    (id: string) => {
      if (isClient) {
        return getRouteClientMarker(id);
      } else {
        return getRouteWebMarker(id);
      }
    },
    [isClient],
  );

  return useRouterMarkerCb;
};
