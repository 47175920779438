import { useEffect } from 'react';
import { useSearchStore } from '../store/searchStore';
import { getFnSearchSetText, getSearchText } from '../selectors/search';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSearchQueryParamsStore } from '../store/searchQueryParamsStore';

export const useSearchQueryParamsService = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  // const location = useLocation();

  const searchText = useSearchStore(getSearchText);
  const setText = useSearchStore(getFnSearchSetText);

  const query = useSearchQueryParamsStore((s) => s.query);
  const setQuery = useSearchQueryParamsStore((s) => s.setQuery);

  useEffect(() => {
    const q = searchParams.get('q');
    if (query !== null && q !== query) {
      // console.log('set search:', query);
      setSearchParams({ q: query }, { replace: true });
    }
  }, [query]);

  useEffect(() => {
    const q = searchParams.get('q');
    if (q !== null && q !== query) {
      // console.log('set query:', q);
      setQuery(q);
    }
  }, [searchParams]);

  useEffect(() => {
    // console.log(query, searchText, searchParams);

    // обновить поиск из адреса при заходе на страницу
    if (searchText === null && query !== null) {
      // console.log('set text:', query, searchText);
      setText(query);

      //обновить адрес из поиска при вводе в поиск, адрес заменяется
    } else if (query !== searchText && searchText !== null) {
      // console.log('setSearchParams', query, searchText, searchParams);
      setQuery(searchText);
    }
    // // обновить поиск из адреса, при переходе по истории
    // } else if (query !== searchText && query !== null) {
    //   console.log('history, setSearchParams:', query, searchText);
    //   setText(query);
    // }
  }, [searchText, query]);

  return;
};
