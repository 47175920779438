import { memoize } from 'proxy-memoize';
import { ClusterSourceStore } from '../store/clusterSourceStore';

export const getClusterSourceIsLoaded = memoize((s: ClusterSourceStore) => s.isSourceLoaded);
export const getClusterSourceGeojson = memoize((s: ClusterSourceStore) => s.allGeojson);
export const getClusterSourceCurrentGeojson = memoize((s: ClusterSourceStore) => s.currentGeojson);

export const getFnClusterSourceIsLoaded = memoize((s: ClusterSourceStore) => s.setIsSourceLoaded);
export const getFnClusterSourceInitGeojson = memoize((s: ClusterSourceStore) => s.initGeojson);
export const getFnClusterSourceCurrentGeojson = memoize(
  (s: ClusterSourceStore) => s.setCurrentGeojson,
);
export const getFnClusterSourceCurrentGeojsonFromAll = memoize(
  (s: ClusterSourceStore) => s.setCurrentGeojsonFromAll,
);
