import { Button, ButtonProps } from 'react-bootstrap';
import s from './TextButton.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { memo } from 'react';

export type TextButtonVariants = 'danger' | 'primary';

const mapVariantToClass: Record<TextButtonVariants, string> = {
  primary: s.primary,
  danger: s.danger,
};

interface TextButtonProps extends Omit<ButtonProps, 'variant'> {
  className?: string;
  variant?: TextButtonVariants;
}

export const TextButton = memo((props: TextButtonProps) => {
  const { className, children, variant = 'primary', ...other } = props;

  const variantClass = mapVariantToClass[variant];

  return (
    <Button
      variant='light'
      className={classNames(s.TextButton, {}, [className, variantClass])}
      {...other}
    >
      {children}
    </Button>
  );
});
