import { Card as BCard, Button, Stack } from 'react-bootstrap';
import s from './Card.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';
import { CardCarousel } from '/entities/CardCarousel/';
import { Icon } from '/shared/ui/Icon';
import shareIcon from '/shared/assets/icons/Union.svg?react';
import { CardMetaInfo } from '/entities/CardMetaInfo/';
import { CardTagsList } from '/entities/CardTagsList/';
import { CardLinks } from '/entities/CardLinks/';
import { CardFooter } from '/entities/CardFooter/';
import { ICard } from '/entities/Card';
import { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Loader } from '/shared/ui/Loader';
import { BaseCard } from '/shared/ui/BaseCard';
import { isMobile } from 'react-device-detect';
import { useRouterMarker } from '/shared/hooks/router/useRouterMarker';

interface CardProps {
  card?: ICard;
  className?: string;
  onShareClick?: (card: ICard) => void;
  onLikeClick?: (card: ICard) => void;
  liked?: boolean;
  loading?: boolean;
  errorText?: string;
  links?: ReactNode;
}

export const Card = memo((props: CardProps) => {
  const { className, card, onShareClick, onLikeClick, liked, errorText, loading, links } = props;

  const [isCopy, setIsCopy] = useState(false);

  const getRouteMarker = useRouterMarker();

  const onShareClickCb = useCallback(() => {
    onShareClick?.(card!);
    setIsCopy(true);
  }, [card]);
  const onLikeClickCb = useCallback(() => onLikeClick?.(card!), [card]);

  useEffect(() => {
    if (isCopy) {
      setTimeout(() => setIsCopy(false), 2000);
    }
  }, [isCopy]);

  if (errorText || !card) {
    return (
      <BaseCard className={classNames(s.Card, {}, [className])}>
        <p>Ошибка при загрузке</p>
      </BaseCard>
    );
  }

  if (loading) {
    return (
      <BaseCard className={classNames(s.Card, {}, [className])}>
        <Loader />
      </BaseCard>
    );
  }

  return (
    <BaseCard className={classNames(s.Card, {}, [className])}>
      <CardCarousel imgSrcs={card.imgSrc} className={s.carousel} />
      <BCard.Body className='mt-2'>
        <Stack direction='horizontal' className={classNames('mb-2', {}, [s.header])}>
          <BCard.Title color='primary' className={s.title}>
            {card.name}
          </BCard.Title>
          <div className={s.btnWrap}>
            <Button variant='light' className={s.shareButton} onClick={onShareClickCb}>
              <Icon Svg={shareIcon} />
            </Button>
            {isCopy && !isMobile && <div className={s.copyText}>Ссылка скопирована</div>}
          </div>
        </Stack>
        <BCard.Text className={classNames(s.text, {}, ['mb-3'])}>{card.description}</BCard.Text>
        <Stack direction='vertical' className='mb-4'>
          <CardMetaInfo
            visits={card.views}
            likes={card.likes}
            liked={liked}
            onLikeClick={onLikeClickCb}
            className={s.metaInfo}
          />
          <CardTagsList tags={card.tags || []} />
        </Stack>
        {links === undefined ? (
          <CardLinks browserURL={card.webUrl} clientrURL={card.metaClientUrl} ARURL={card.arUrl} />
        ) : (
          links
        )}
      </BCard.Body>
      <BCard.Footer className={s.footer}>
        <CardFooter link={card.link} linkSrc={getRouteMarker(card.link)} />
      </BCard.Footer>
    </BaseCard>
  );
});
