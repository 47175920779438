import { create } from 'zustand';
import { FeatureCollection } from '/entities/Marker';
import { initialGeojson } from '../const/initialGeojson';

/**
 * Хранилище geojson для maplibre
 */

export type ClusterSourceStore = {
  isSourceLoaded: boolean;
  allGeojson: FeatureCollection;
  currentGeojson: FeatureCollection | null;
  setIsSourceLoaded: (isSourceLoaded: boolean) => void;
  initGeojson: (allGeojson: FeatureCollection) => void;
  setCurrentGeojson: (currentGeojson: FeatureCollection) => void;
  setCurrentGeojsonFromAll: () => void;
};

export const useClusterSourceStore = create<ClusterSourceStore>()((set, get) => ({
  isSourceLoaded: false,
  allGeojson: initialGeojson,
  currentGeojson: null,
  setIsSourceLoaded: (isSourceLoaded) => set({ isSourceLoaded }),
  initGeojson: (allGeojson) => set({ allGeojson }),
  setCurrentGeojson: (currentGeojson) => set({ currentGeojson }),
  setCurrentGeojsonFromAll: () => set({ currentGeojson: get().allGeojson }),
}));
