import {
  getSearchFilterProjectItems,
  getFnSetSearchFilterProjectToggle,
  getFnSetSearchFilterProjectReset,
  getSearchFilterProjectItemsChanged,
} from '../../model/selector/searchFilter';
import { useSearchFilterStore } from '../../model/store/searchFilter';
import { FilterCheckboxList } from '/entities/FilterCheckboxList';

export const ProjectFilter = () => {
  const projectFilterItems = useSearchFilterStore(getSearchFilterProjectItems);
  const toggleProjectFilterItem = useSearchFilterStore(getFnSetSearchFilterProjectToggle);
  const resetProjectFilterItems = useSearchFilterStore(getFnSetSearchFilterProjectReset);
  const searchFilterProjectItemsChanged = useSearchFilterStore(getSearchFilterProjectItemsChanged);

  return (
    <FilterCheckboxList
      eventKey='1'
      title='Проекты'
      changed={searchFilterProjectItemsChanged}
      onCheckClick={toggleProjectFilterItem}
      onReset={resetProjectFilterItems}
      items={projectFilterItems || []}
    />
  );
};
