import { Route, Routes } from 'react-router-dom';
import {
  getRouteClientMain,
  getRouteClientMarker,
  getRouteClientSearch,
  getRouteMain,
  getRouteWebMarker,
  getRouteWebSearch,
} from '/shared/const/router';
import MainPage from '/pages/MainPage';
import { CardSidebar } from '/widgets/CardSidebar/';
import { NotFoundPage } from '/pages/NotFoundPage';
import { memo } from 'react';
import { CardSearch } from '/widgets/CardSearch';
import ClientMainPage from '/pages/ClientMainPage';

export const AppRouter = memo(() => {
  return (
    <Routes>
      <Route path={getRouteMain()} element={null}>
        <Route path={getRouteMain()} element={<MainPage />}>
          <Route path={getRouteWebSearch()} element={<CardSearch />} />
          <Route path={getRouteWebMarker(':id')} element={<CardSidebar />} />
        </Route>
        <Route path={getRouteClientMain()} element={<ClientMainPage />}>
          <Route path={getRouteClientSearch()} element={<CardSearch />} />
          <Route path={getRouteClientMarker(':id')} element={<CardSidebar />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
});
