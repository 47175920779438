import { FeatureCollection, IMarker, GeoMarkerFeature } from '/entities/Marker';

const mapMarkerToFeature = ({
  name,
  link,
  logoSrc,
  lon,
  lat,
  tags,
  project,
  number,
}: IMarker): GeoMarkerFeature => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [lon, lat],
  },
  id: number,
  properties: {
    name,
    link,
    logoSrc,
    lon,
    lat,
    tags,
    project,
    number,
  },
});

export function mapMarkersToFeatureCollection(markers: IMarker[]): FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: markers.map(mapMarkerToFeature),
  };
}
