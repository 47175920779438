import { Options } from 'axios-hooks';
import { useAppAxios } from '/shared/api/axios';
import { ErrorResponse } from '/shared/api/type';
import { SearchRequest, SearchResponse } from '../model/type/searchResponse';

export function useSearch(data: SearchRequest, options?: Options) {
  return useAppAxios<SearchResponse, SearchRequest, ErrorResponse>(
    {
      url: `/marker/search`,
      method: 'POST',
      data,
    },
    {
      useCache: true,
      manual: true,
      ...options,
    },
  );
}
