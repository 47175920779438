import { useEffect } from 'react';
import { useSearchFilterStore } from '../store/searchFilter';
import { getProjects, useGetProjectsService, useProjectStore } from '/entities/Project';
import { mapProjectToFilterItem } from '../../lib/mapProjectToFilterItem';
import { getFnSetSearchFilterProjectItems } from '../selector/searchFilter';

export const useGetProjectFilterItemsService = () => {
  const setProjectFilterItems = useSearchFilterStore(getFnSetSearchFilterProjectItems);
  const projects = useProjectStore(getProjects);

  useGetProjectsService();

  useEffect(() => {
    if (projects) {
      const mapped = mapProjectToFilterItem(projects);
      setProjectFilterItems(mapped);
    }
  }, [projects]);

  return;
};
