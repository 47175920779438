import { useCallback } from 'react';
import { IMarker, MarkersList } from '/entities/Marker';
import {
  useSearchStore,
  getFnSearchSetIsBackBtn,
  useSearchQueryParamsService,
  useSearchResultFilterService,
} from '/features/Search';
import { SearchFilter } from '/features/SearchFilter';
import { BaseCard } from '/shared/ui/BaseCard';
import { useMapStore, getFnMapFlyTo } from '/widgets/MapGL';

export const CardSerchFeature = () => {
  // const [selectMListItem, setSelectMListItem] = useState<undefined | IMarker>(undefined);

  const setFlyTo = useMapStore(getFnMapFlyTo);
  const setIsBackBtn = useSearchStore(getFnSearchSetIsBackBtn);

  useSearchQueryParamsService();
  const searchFilteredResult = useSearchResultFilterService();

  const onClickListItem = useCallback((marker: IMarker) => {
    // setSelectMListItem(marker);
    setFlyTo([marker.lon, marker.lat]);
    setIsBackBtn(true);
  }, []);

  // const onMouseEnterListItem = useCallback((marker: IMarker) => {
  //   setFlyTo([marker.lon, marker.lat]);
  // }, []);

  return (
    <BaseCard>
      <SearchFilter className='mb-3' />
      <MarkersList
        markerFeatures={searchFilteredResult || []}
        onClick={onClickListItem}
        // onMouseEnter={onMouseEnterListItem}
        // selectedMarker={selectMListItem}
      />
    </BaseCard>
  );
};
