import { useEffect } from 'react';
import { useSearch } from '../../api/useSearch';
import { useSearchStore } from '../store/searchStore';
import {
  getFnSearchSetRawResult,
  getFnSearchSetText,
  getSearchFieldValue,
  getSearchParamLimit,
  getSearchParamSkip,
  getSearchText,
} from '../selectors/search';
import { useDebounce } from '/shared/hooks/useDebounce';

export const useSearchService = () => {
  const fieldValue = useSearchStore(getSearchFieldValue);
  const searchText = useSearchStore(getSearchText);
  const paramLimit = useSearchStore(getSearchParamLimit);
  const paramSkip = useSearchStore(getSearchParamSkip);
  const setSearchText = useSearchStore(getFnSearchSetText);
  const setRawResult = useSearchStore(getFnSearchSetRawResult);

  const [{ data }, searchData] = useSearch({
    query: searchText || '',
    size: paramLimit,
    skip: paramSkip,
  });

  const debouncedSetSearchText = useDebounce(setSearchText, 1000);

  useEffect(() => debouncedSetSearchText(fieldValue), [fieldValue]);

  const debouncedSearchData = useDebounce(searchData, 100);

  useEffect(() => {
    if (searchText !== null) {
      debouncedSearchData();
    }
  }, [searchText, searchData]);

  useEffect(() => {
    if (data?.data?.results) {
      try {
        // console.log('setRawResult', data?.data?.results);
        setRawResult(data.data.results);
      } catch (error) {
        console.log(error);
      }
    }
  }, [data?.data?.results]);

  return searchData;
};
