import { useEffect } from 'react';
import { useSearchFilterStore } from '../store/searchFilter';
import { getTag, useGetTagsService, useTagStore } from '/entities/Tag';
import { mapTagToFilterItem } from '../../lib/mapTagToFilterItem';
import { getFnSetSearchFilterTagItems } from '../selector/searchFilter';

export const useGetTagFilterItemsService = () => {
  const setTagFilterItems = useSearchFilterStore(getFnSetSearchFilterTagItems);
  const tags = useTagStore(getTag);

  useGetTagsService();

  useEffect(() => {
    if (tags) {
      const mapped = mapTagToFilterItem(tags);
      setTagFilterItems(mapped);
    }
  }, [tags]);

  return;
};
