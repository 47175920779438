import { Card as BCard, CardProps } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import s from './BaseCard.module.scss';
import { classNames } from '/shared/lib/classNames/classNames';

interface BaseCardProps extends CardProps {
  className?: string;
}

export const BaseCard = (props: BaseCardProps) => {
  const { className, children, ...other } = props;

  return (
    <BCard className={classNames(s.BaseCard, {}, [className])} border='none' bg='Light' {...other}>
      {!isMobile && <div className={s.searchPlace}></div>}
      <div className={s.children}>{children}</div>
    </BCard>
  );
};
